<template>
  <div class="full-page">
    <nav-bar>
      <div class="text" v-html="content"></div>
    </nav-bar>
  </div>
</template>

<script>// @ts-nocheck

import navBar from '@/components/navBar/index.vue'
export default {
  components: {
    navBar
  },
  data() {
    return {
      content: ''
    }
  },

  created() {
    console.log('轮播图详情')

    this.content = localStorage.getItem('banner-detail')
  }
}
</script>

<style lang="scss" scoped>
.full-page {
  .text {
    width: 1200px;
    padding: 20px 0;
    margin: 0 auto;
    color: #000;
    font-size: 18px;
    line-height: 27px;
    ::v-deep p {
      // 文字两边对齐
      text-align: justify;
      word-wrap: break-word;
      img {
        display: block;
        max-width: 100%;
        margin: 20px auto;

        object-fit: cover;
      }
    }
  }
}
</style>
